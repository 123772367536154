import { ProfileService } from "../../services";

const PROFILE = {
    SEND: 'golosmarket/user/profile/SEND',
    LOADED: 'golosmarket/user/profile/LOADED',
    FAIL: 'golosmarket/user/profile/FAIL',
    SCHEDULE_SEND: 'golosmarket/user/profile/schedule/SEND',
    SCHEDULE_LOADED: 'golosmarket/user/profile/schedule/LOADED',
    SCHEDULE_FAIL: 'golosmarket/user/profile/schedule/FAIL',
    READ_SEND: 'golosmarket/user/profile/notify/read/SEND',
    READ_LOADED: 'golosmarket/user/profile/notify/read/LOADED',
    READ_FAIL: 'golosmarket/user/profile/notify/read/FAIL',
    PAYMENT_SEND: 'golosmarket/user/payed/SEND',
    PAYMENT_LOADED: 'golosmarket/user/payed/LOADED',
    PAYMENT_FAIL: 'golosmarket/user/payed/FAIL',
    BILL_SEND: 'golosmarket/user/profile/bill/SEND',
    BILL_LOADED: 'golosmarket/user/profile/bill/LOADED',
    BILL_FAIL: 'golosmarket/user/profile/bill/FAIL',
};

export function getProfile() {
    return dispatch => {
        dispatch({
            type: PROFILE.SEND
        });
        return ProfileService
            .get()
            .then(response => response.data)
            .then(data => {
                dispatch({
                    type: PROFILE.LOADED,
                    payload: {
                        data: data,
                        inProgress: false
                    }
                })
            })
            .catch(() => {
                dispatch({
                    type: PROFILE.FAIL,
                })
            });
    }
}

export function getPaymentInfo(){

    return dispatch => {
        dispatch({
            type: PROFILE.PAYMENT_SEND
        });
        return ProfileService
            .getPayment()
            .then(response => response.data)
            .then(data => {
                dispatch({
                    type: PROFILE.PAYMENT_LOADED,
                    payload: {
                        payed: data,
                    }
                })
            })
            .catch(() => {
                dispatch({
                    type: PROFILE.PAYMENT_FAIL,
                })
            });
    }
}


export function updatePayment(){
    ProfileService.updatePayment()
}

export function getBills(page = 1) {
    return async dispatch => {
        dispatch({
            type: PROFILE.BILL_SEND
        });
        try {
            const response = await ProfileService.bill({page});
            const bills = response.data;
            const pagination = {
                page: +response.headers['x-pagination-current-page'],
                quantityPages: +response.headers['x-pagination-page-count'],
                perPage: +response.headers['x-pagination-per-page'],
                totalCount: +response.headers['x-pagination-total-count'],

            };
            dispatch({
                type: PROFILE.BILL_LOADED,
                payload: {
                    bills,
                    pagination,
                }
            });
        } catch (e) {
            console.error(e);
            dispatch( {
                type: PROFILE.BILL_FAIL,
            });
        }
    }
}

export function getProfileLoad() {
    return dispatch => {
        return ProfileService
            .get()
            .then(response => response.data)
            .then(data => {
                dispatch({
                    type: PROFILE.LOADED,
                    payload: {
                        data: data,
                        inProgress: false
                    }
                })
            })
            .catch(() => {
                dispatch({
                    type: PROFILE.FAIL,
                })
            });
    }
}

export function clearUser() {
    return {type: PROFILE.LOADED, payload: {}};
}


export function read(ids) {
    return dispatch => {
        dispatch({
            type: PROFILE.READ_SEND
        });
        return ProfileService
            .read({ ids })
            .then(response => response.data)
            .then(data => dispatch({
                type: PROFILE.READ_LOADED,
                payload: {
                    data: data,
                    inProgress: false
                }
            }))
            .catch(() => dispatch({
                type: PROFILE.READ_FAIL,
            }));
    }
}

export function updateSchedule(data) {
    return dispatch => {
        dispatch({
            type: PROFILE.SCHEDULE_SEND,
        });

        return ProfileService
            .scheduleUpdate(data)
            .then(response => response.data)
            .then(data => dispatch({
                type: PROFILE.SCHEDULE_LOADED,
                payload: {
                    data,
                    inProgress: false
                }
            }))
            .catch(() => dispatch({
                type: PROFILE.SCHEDULE_FAIL,
            }));
    }
}

const initialState = { isLoading: false, user: null, payed: null, bills: [], billsIsLoading: false, pagination: {} };

export const user = (state = initialState, { type, payload }) => {
    switch (type) {
        
        case PROFILE.SEND:
            return {
                ...state,
                isLoading: true
            };

        case PROFILE.PAYMENT_SEND:
            return {
                ...state,
                isLoading: true
            };    

        case PROFILE.BILL_SEND:
            return {
                ...state,
                billsIsLoading: true
            };

        case PROFILE.PAYMENT_LOADED:
            return {
                ...state,
                payed: payload.payed,
                isLoading: false
            };
        case PROFILE.LOADED:
            return {
                ...state,
                user: payload.data,
                isLoading: false
            };

        case PROFILE.READ_LOADED:
            return {
                ...state,
                user: payload.data,
                isLoading: false
            };

        case PROFILE.SCHEDULE_LOADED:
            return {
                ...state,
                user: payload.data,
                isLoading: false
            };

        case PROFILE.BILL_LOADED:
            return {
                ...state,
                bills: payload.bills,
                pagination: payload.pagination,
                billsIsLoading: false
            };

        case PROFILE.FAIL:
            return {
                ...state,
                isLoading: false,
                message: payload,
            };
        case PROFILE.PAYMENT_FAIL:
            return {
                ...state,
                isLoading: false,
                message: payload,
            };

        case PROFILE.BILL_FAIL:
            return {
                ...state,
                billsIsLoading: false,
                message: payload,
            };

        default:
            return state;
    }
};
