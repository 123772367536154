import API from '../api/api';

const boolToInt = (val) => typeof val === 'boolean' ? (val ? 1 : 0) : val;

export const requiredField = value => {
  return value ? undefined : 'Поле не может быть пустым'
};
export const requiredFieldAboveZero = value => {
  let result = 'Введите целое число больше 0';

  if (String(value).length === 0) {
    result = undefined;
  } else {
    if (value && parseInt(value) > 0 && parseInt(String(value).slice(0, 1)) > 0) {
      result = undefined;
    }
  }

  return result;
}

export const ParseToRequest = (form) => {
  if (!form) return '';

  return `?${Object.keys(form)
    .filter(key => !!form[key] || form[key] === 0)
    .map(key => {
      if (key === 'id') {
        return form[key].map((id, index) => `id[${index}]=${id}&`).join('').replace(/&$/, '')
      } else {
        return `${key}=${boolToInt(form[key])}`
      }
    }).join('&')}`;
};

export const isMobileScreen = () => {
  return window.outerWidth <= 800;
};

export const linkParser = txt => {
  // eslint-disable-next-line
  const linkRx = /^(https?:\/\/)?([\w-]{1,32}\.[\w-]{1,32})[^\s@]*$/gm;

  txt.split(' ').forEach(word => {
    const link = word.match(linkRx)
    if (link) {
      txt = txt.replace(link, '<a href="' + link + '" target="_blank">' + link + '</a>')
    }
  });

  return txt;
}

export const isMobileBrowser = () => {
  let check = false;
  // eslint-disable-next-line
  (function (a) {
    // eslint-disable-next-line
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check || window.innerWidth < 543;
};

export const getJsonData = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return {};
  }
  return JSON.parse(str);
};

export const prepareRequest = (type = 'get', url, form) => {
  return API[type](url, form);
};

export const arrayMove = (array, from, to) => {
  const newArray = array.slice();

  newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);
  return newArray;
};

export const Plural = (n, base, words) => {
  const number = Math.abs(parseInt(n));

  let resultWord = words[0];

  if (number % 100 > 10 && number % 100 < 20) resultWord = words[2];
  else if (number % 10 === 1) resultWord = words[0];
  else if (number % 10 >= 2 && number % 10 <= 4) resultWord = words[1];
  else resultWord = words[2];

  return base + resultWord;
};

export const dateTimeFormat = (date) => {
  // options = {
  //     year: 'numeric', month: 'numeric', day: 'numeric',
  //     hour: 'numeric', minute: 'numeric', second: 'numeric',
  //     hour12: false
  // };
  // return new Intl.DateTimeFormat().format(new Date(date))

  return (new Date(date)).toLocaleString();
}

export const clearFilter = (filter) => {
  const newFilter = {};
  for (let [key, value] of Object.entries(filter)) {
    if (value) {
      newFilter[key] = value;
    }
  }

  return newFilter;
};

export const isMobile = () => window.matchMedia("(max-width: 543px)").matches;
